body {
  margin: 0;
  font-family: 'Exo 2';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  #footer {
    #download-section {
      a {
        color: white;
        margin-top: 15px;
        background-color: #FB8C38;
        height: 50px;
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: bold;
        cursor: pointer;
        text-decoration: none;
      }
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
