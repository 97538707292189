@import '../style/variables.scss';
@import '../style/firefox-only.scss';
@import '../style/ie-only.scss';
@import '../style/hover.scss';

.radio-btns--wrapper {
  label {
    color: $color-blue;
    cursor: pointer;
    display: block;
    font-size: 22px;
    margin-bottom: 12px;
    padding-left: 35px;
    position: relative;

    .radio-btn {
      background-color: $color-gray;
      border: 1px solid $color-blue;
      border-radius: 50%;
      height: 20px;
      left: 0;
      position: absolute;
      top: 0;
      width: 20px;
    }
    input {
      cursor: pointer;
      opacity: 0;
      position: absolute;

      &:checked ~ .radio-btn {
        background-color: $color-orange-dark;
        border: 1px solid $color-orange-dark;
      }
    }
  }
}

.lang-container {
  padding: 30px 5% 0;
  width: 50%;
}

.flex-container.about-section {
  @media screen and (min-width: 1024px) {
    height: 800px !important;
  }
}

.parent-wrapper {

  .desktop-menu {
    //tablet padding
    @media screen and (min-width: 425px) and (max-width: 1024px) {
      padding: 120px 40px 0 40px !important;
    }
  }

  .main-link {
    background: $color-orange;
    width: 36%;
    padding: 15px 30px;
    margin-top: 40px;
    font-size: 20px;
    color: #fff;
    font-weight: 700;
    @media screen and (max-width: 769px) {
      padding: 20px 10px;
      width: 250px;
    }
  }

  .what-is-section {
    //tablet padding
    @media screen and (min-width: 425px) and (max-width: 1024px) {
      padding-right: 0px !important;
    }
  }

  .home-section {
    //tablet padding
    @media screen and (min-width: 425px) and (max-width: 1024px) {
      margin-top: -30px !important;
    }
  }

  .star-hero-img {
    width: 20px;
    @include ie-only {
      width: auto;
    }
  }

  .driver-license {
    
    //tablet padding
    @media screen and (min-width: 425px) and (max-width: 1024px) {
      left: -4% !important;
    }
    @include firefox-only {
      margin-top: -910px;
    }
    @media screen and (min-width: 1024px) and (max-width: 1580px) {
      @include ie-only {
        margin-top: -305px;
      }
    }
    margin-top: -430px;
  }

  .cards-container {
    @media screen and (min-width: 425px) and (max-width: 1024px) {
      padding: 180px 70px !important;
    }
  }

  .image-card {
    box-shadow: 0 0 24px -7px rgba(48,48,48,0.25);
    max-width: 350px;

    .image-star {
      box-shadow: 0 0 24px -7px rgba(48,48,48,0.25);

      @include ie-only {
        box-shadow: 0 0 65px -7px rgba(48,48,48,0.25);
        left: 35%;
      }
    }
    @include ie-only {
      box-shadow: 0 0 65px -7px rgba(48,48,48,0.25);
    }
    @media screen and (min-width: 425px) and (max-width: 1024px) {
      max-width: 230px !important;
    }
  }

  .checks-card {
    box-shadow: 0 0 24px -7px rgba(48,48,48,0.25);
    max-width: 350px;

    @include ie-only {
      box-shadow: 0 0 65px -7px rgba(48,48,48,0.25);
    }
    @media screen and (min-width: 425px) and (max-width: 1280px) {
      max-width: 280px !important;
      height: 465px !important;
    }
  }

  .checks-container {
    @media screen and (min-width: 425px) and (max-width: 1024px) {
      min-width: 90%;
    }
  }

  .blue-divider {
    @include firefox-only {
      margin-top: 800px;
    }
    @include ie-only {
      margin-top: 99px;
    }
    right: 0;
    margin-top: 500px;
  }

  .hide-section {
    margin-top: 350px;
  }
}

.footer-container {
  @media screen and (min-width: 425px) and (max-width: 1024px) {
    padding: 25px 150px !important;
  }
}